import _ from 'lodash'

export default {
  methods: {
    whatsappFormatter(text) {
      if (text) {
        let newText = text
        newText = newText.replace(/<b>(.*?)<\/b>/g, '*$1*')
        newText = newText.replace(/<i>(.*?)<\/i>/g, '_$1_')
        newText = newText.replace(/<strike>(.*?)<\/strike>/g, '~$1~')
        newText = newText.replace(/<br \/>/g, '\n')
        newText = newText.replace(/<strong>(.*?)<\/strong>/g, '*$1*')
        newText = newText.replace(/<em>(.*?)<\/em>/g, '_$1_')
        newText = newText.replace(/<s>(.*?)<\/s>/g, '~$1~')
        return newText
      }
    },
    whatsappToPreviewFormatter(text, usingBr = false) {
      if (text) {
        let newText = text
        const regex = {
          bold: /(?<!\*)\*([^*\n]+)\*(?!\*)/g,
          italic: /\b_([^\s][^_]+?[^\s])_\b/g,
          strikethrough: /~\b(\w+(\s+\w+)*|\w+)\b~(?!\w)/g,
          boldItalic: /\b\*_([^\s][^_]+?[^\s])_\*\b/g,
          italicBold: /\b_\*([^\s][^_]+?[^\s])\*_\b/g,
        }
        text.replace(regex.italicBold, (output) => {
          let isStyled = true
          if (output[1] === ' ') isStyled = false
          if (output[output.length - 2] === ' ') isStyled = false
          if (isStyled) {
            newText = newText.replace(regex.italicBold, '<i><b>$1</b></i>')
          }
        })
        text.replace(regex.boldItalic, (output) => {
          let isStyled = true
          if (output[1] === ' ') isStyled = false
          if (output[output.length - 2] === ' ') isStyled = false
          if (isStyled) {
            newText = newText.replace(regex.boldItalic, '<b><i>$1</i></b>')
          }
        })

        text.replace(regex.bold, (output) => {
          let isStyled = true
          if (output[1] === ' ') isStyled = false
          if (output[output.length - 2] === ' ') isStyled = false

          if (isStyled) {
            // source: https://stackoverflow.com/questions/6182070/regex-to-replace-asterisk-characters-with-html-bold-tag
            // eslint-disable-next-line no-useless-escape
            newText = newText.replace(regex.bold, '<b>$1</b>')
          }
        })

        text.replace(regex.italic, (output) => {
          let isStyled = true
          if (output[1] === ' ') isStyled = false
          if (output[output.length - 2] === ' ') isStyled = false
          if (isStyled) {
            newText = newText.replace(regex.italic, '<i>$1</i>')
          }
        })

        text.replace(regex.strikethrough, (output) => {
          let isStyled = true
          if (output[1] === ' ') isStyled = false
          if (output[output.length - 2] === ' ') isStyled = false
          if (isStyled) {
            newText = newText.replace(
              regex.strikethrough,
              '<strike>$1</strike>'
            )
          }
        })

        // newText = format(newText, waRule)

        if (!usingBr) {
          newText = newText.replace(/\r\n|\n|\r/g, ' <br />')
        }

        // check URL
        newText = this.hubConversationLinkFormatter(newText)
        if (this.$route.query.type === 'comments')
          newText = this.hubConversationUsernameFormatter(newText)
        return newText
      } else {
        return ''
      }
    },
    scrollToBottomConversation() {
      const objDiv = document.getElementById('conv')
      objDiv.scrollTop = objDiv.scrollHeight
    },
    hubConversationFormatter(text) {
      if (text) {
        return this.whatsappToPreviewFormatter(text)
      }
    },
    reformatUrl(url) {
      const isNumericFirst = /^\d+(?:[.,]\d+)*$/gm.test(url)
      if (!isNumericFirst) {
        let redirectUrl = url.includes('http') ? url : 'https://' + url
        redirectUrl = redirectUrl.replace(/[,+()$~'"*<>{}]/g, '') // Symbol punctuation
        redirectUrl = redirectUrl.replace(/<b>(.*?)<\/b>/g, '$1')
        redirectUrl = redirectUrl.replace(/<i>(.*?)<\/i>/g, '$1')
        redirectUrl = redirectUrl.replace(/<strike>(.*?)<\/strike>/g, '$1')
        redirectUrl = redirectUrl.replace(/(.*?)<\/b>/g, '$1')
        redirectUrl = redirectUrl.replace(/(.*?)<\/i>/g, '$1')
        redirectUrl = redirectUrl.replace(/(.*?)<\/strike>/g, '$1')
        redirectUrl = redirectUrl.replace(/<br \/>/g, '\n')
        redirectUrl = redirectUrl.replace(/<br \/>/g, '\n')

        return (
          '<a href="' +
          redirectUrl.replace(/(.*?)<\//g, '$1') +
          '" style="word-break: break-all; color: #4B61DD" target="_blank"><u>' +
          this.whatsappFormatter(url) +
          '</u></a>'
        )
      } else return url
    },
    hubConversationLinkFormatter(text) {
      // Save for later
      let urlRegex
      if (text.includes('//')) {
        urlRegex =
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
      } else {
        urlRegex =
          /[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi
      }

      return text.replace(urlRegex, (url) => this.reformatUrl(url))
      // return text
    },
    bracketClear(message) {
      if (message) {
        if (message) message = message.replaceAll('<', '< ')
        return message
      }
    },
    contentFormatter(message, isRead = false) {
      if (message) {
        let text = isRead ? message : this.sliceWords(message, 253)
        let indexingText = isRead ? message : this.sliceWords(message, 253)
        let currentPoint = 0
        let bunchOfUrls = []
        const outputResponse = []
        const urlRegex =
          /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi

        text.replace(urlRegex, (url) => {
          // Check if there is currency
          const isUsingCurrency = url[0] + url[1] === 'Rp'
          let extractAfterCurrency = url
          if (isUsingCurrency) extractAfterCurrency = url.split('Rp')[1]
          if (!parseInt(extractAfterCurrency)) {
            const index = []
            index.push(indexingText.indexOf(url))
            index.forEach((idx) => {
              const isUsingProtocol =
                indexingText[idx - 2] === '/' && indexingText[idx - 1] === '/'
              const protocol = indexingText[idx - 4] === 's' ? 'https' : 'http'
              // eslint-disable-next-line no-useless-escape
              // url = url.replace(/[,+()$~'"*<>{}]/g, '') // url punctuation
              bunchOfUrls.push({
                link: isUsingProtocol ? `${protocol}://${url}` : url,
                index: isUsingProtocol
                  ? currentPoint + idx + (protocol.length + 3)
                  : currentPoint + idx,
              })
              indexingText = indexingText.replace(
                isUsingProtocol ? `${protocol}://${url}` : url,
                ''
              )
              currentPoint =
                (isUsingProtocol
                  ? currentPoint + idx + (protocol.length + 3)
                  : currentPoint + idx) + url.length
            })
          }
        })

        bunchOfUrls = _.sortBy(bunchOfUrls, (url) => url.index) // sort by index
        bunchOfUrls = _.uniqBy(bunchOfUrls, 'index') // unique by index

        bunchOfUrls.forEach((url, index) => {
          try {
            text = text.replace(url.link, `#~${index}`) // make sure every url.link is unique
            const textBefore = text.split(`#~${index}`)[0]
            const textAfter =
              index + 1 < bunchOfUrls.length ? '' : text.split(`#~${index}`)[1]
            outputResponse.push({
              url: url.link,
              textBefore,
              textAfter,
            })
            text = text.split(`#~${index}`)[1]
          } catch (err) {
            return bunchOfUrls.length > 0
              ? outputResponse
              : [
                  {
                    url: '',
                    textBefore: isRead
                      ? this.whatsappToPreviewFormatter(message, true)
                      : this.sliceWords(
                          this.whatsappToPreviewFormatter(message, true),
                          256
                        ),
                    textAfter: '',
                  },
                ]
          }
        })

        return bunchOfUrls.length > 0
          ? outputResponse
          : [
              {
                url: '',
                textBefore: isRead
                  ? this.whatsappToPreviewFormatter(message, true)
                  : this.sliceWords(
                      this.whatsappToPreviewFormatter(message, true),
                      256
                    ),
                textAfter: '',
              },
            ]
      } else return ''
    },
    hubConversationUsernameFormatter(text) {
      const urlRegex = /@\w+/
      return text.replace(urlRegex, (uname) => this.reformatUsername(uname))
    },
    reformatUsername(uname) {
      let username = uname
      username = username.replace(/<b>(.*?)<\/b>/g, '$1')
      username = username.replace(/<i>(.*?)<\/i>/g, '$1')
      username = username.replace(/<strike>(.*?)<\/strike>/g, '$1')
      username = username.replace(/(.*?)<\/b>/g, '$1')
      username = username.replace(/(.*?)<\/i>/g, '$1')
      username = username.replace(/(.*?)<\/strike>/g, '$1')
      username = username.replace(/<br \/>/g, '\n')

      return (
        '<a href="https://instagram.com/' +
        username.replace('@', '') +
        '" style="word-break: break-all; color: #4B61DD" target="_blank">' +
        this.whatsappFormatter(username) +
        '</a>'
      )
    },
  },
}
